<template>
  <div v-if="totalRecords > 0">
    <v-paginate
      v-if="totalRecords > 0"
      v-model="page"
      :pages="Math.ceil(totalRecords / (!perPage ? 10 : perPage))"
      :range-size="1"
      :hideFirstButton="true"
      :hideLastButton="true"
      active-color="#ff7200"
      @update:modelValue="fetchData"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
    };
  },
  props: ["totalRecords", "pageNo", "perPage","type"],
  methods: {
    fetchData(pageNum) {
      this.$parent.page = pageNum;
      if(this.type == 'openposition'){
        this.$parent.openPositionList()
      }else if(this.type == 'Real-Investors'){
        this.$parent.callLiveFollowersList()
      } else if(this.type == 'dashboardTrades') {
        this.$parent.tradesTypeData()
      }else if(this.type == 'notification') {
        this.$parent.callNotificationList()
      } else if(this.type == 'cancelledOrder') {
        this.$parent.cancelledOrder()
      } else if(this.type == 'tradeHistory') {
        this.$parent.history('change')
      } else {
        this.$parent.pageListing()
      }
    },
  },
};
</script>
